import { usePagesListQuery, PagesListQuery } from '../../generated/graphql';
import React from 'react';
import { Route } from 'react-router';
import PageContent from './PageContent';

interface PagesProps {
    pages: PagesListQuery
}

const PagesRoutes = () => {
    const { data, error, loading } = usePagesListQuery();

    if (loading) {
        return <div></div>;
    }

    if (error || !data) {
        return <div></div>;
    }

    console.log(data);

    return (
        <React.Fragment>
            <Pages pages={data} />
        </React.Fragment>

    )
};

const Pages: React.FC<PagesProps> = ({ pages }) => {
    return (
        <React.Fragment>
            {!!pages.pages &&
                pages.pages.map((page, key) => !!page && (
                    <Route key={key} path={`${page.url}`} render={() => <PageContent content={page.content} title={page.title} />} />
                ))}
        </React.Fragment>);
};


export default PagesRoutes

