import React from "react";
import { fetchHijriDate } from "../../api/timetable";
import moment from "moment";

export class HijriDate extends React.Component<any, any> {

    constructor(props: any) {
        super(props)
        this.state = {
            hijriDateString: null
        };
    }
    
    render() {
        const {hijriDateString}  = this.state;
        return (
            <div className={this.props.className}  id={this.props.id}>
            {hijriDateString ? hijriDateString : ''} 
            </div>
        );
    }
    
    componentDidMount() {
        this.getHijriDate()
    }        

    getHijriDate() {
        const currentDate = moment();
        fetchHijriDate(currentDate).then(response => {
            const hijriDate = response.data.hijri
            const hijriDateString = hijriDate ? `${hijriDate.day} ${hijriDate.month.en} ${hijriDate.year}` : '';
            this.setState({ hijriDateString: hijriDateString});
        })
    }
}