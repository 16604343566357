import React from 'react';
import { EventInfoQuery } from "../../generated/graphql";
import Moment from "react-moment";
import ReactMarkdown from 'react-markdown'




interface Props {
    data: EventInfoQuery;
}

function stripZ(value: any) {
    return value.replace('Z', '');

}

const EventInfo: React.FC<Props> = ({ data }) =>
    <div className={'event-info'}>

        {!!data && !!data.event && (
            <div>
                <h1 className={'event-title'}>{data.event.title}</h1>
                <br />

                <div className={'event-info-text-container text-center'}>
                    <p className={'event-info-text'}><i className="fas fa-user" /> {data.event.contact}</p>
                    <p className={'event-info-text'}><i className="fas fa-map-marker-alt" /> {data.event.location}</p>
                    <p className={'event-info-text'}>
                        <i className="far fa-calendar-alt" /> <Moment
                            format={"dddd Do MMMM YYYY"}>{stripZ(data.event.startDate)}</Moment>&nbsp;&nbsp;
                                <i className="far fa-clock" /> <Moment
                            format={"ha"}>{stripZ(data.event.startDate)}</Moment> - <Moment
                                format={"ha"}>{stripZ(data.event.endDate)}</Moment>
                    </p>
                </div>
                <div className={'event-description'}>
                    <ReactMarkdown>
                        {data.event.description}

                    </ReactMarkdown>
                </div>
                <div className={'text-center'}>
                <img className={'event-image-large'}
                    src={`${process.env.REACT_APP_CMS_API}${data.event.image && data.event.image.url}`}
                    alt="Placeholder" />
                </div>
            </div>

        )}

    </div>;


export default EventInfo;
