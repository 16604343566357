import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom'
import HomeContainer from './containers/home/HomeContainer';
import EventsContainer from './containers/events/EventsContainer';
import ContactContainer from './containers/contact/ContactContainer';
import Header from './components/layout/header/Header';
import { ScreenTypesResolutionDown, ScreenTypes } from './enums/ScreenTypes';
import { HeaderLink } from './components/layout/header/types/HeaderLink';
import Logo from './assets/img/logo.png';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { TimetableContainer } from "./containers/timetable/TimetableContainer";
import { Footer } from './components/layout/footer/Footer';
import EventsInfoContainer from "./containers/event-info/EventsInfoContainer";
import DownloadsContainer from "./containers/downloads/DownloadsContainer";
import history from './history';
import { TimetableScreenContainer } from "./containers/timetable-screen/TimetableScreen";
import RedirectsRoutes from './components/redirects/Redirects';
import PagesRoutes from './components/dynamic-pages/Pages';
import FinancialContainer from './containers/financial/FinancialContainer';
import AboutContainer from './containers/about/AboutContainer';
import Strivefor5Container from "./containers/strivefor5/Strivefor5Container";


const client = new ApolloClient({
  uri: process.env.REACT_APP_CMS_GRAPHQL
});


export interface IAppState {
  currentScreenState: string;
}

class App extends Component<{}, IAppState>{
  constructor(props: any) {
    super(props);

    this.state = {
      currentScreenState: 'desktop'
    }
  }

  componentDidMount() {
    this.getScreenState();
    window.addEventListener('resize', this.getScreenState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getScreenState);
  }

  static makeHeaderLinks(): HeaderLink[] {
    return [
      new HeaderLink({
        id: 'home',
        name: 'HOME',
        path: '/'
      }),
      new HeaderLink({
        id: 'about',
        name: 'ABOUT US',
        path: '/about-us'
      }),
      // new HeaderLink({
      //   id: 'events',
      //   name: 'EVENTS',
      //   path: '/events'
      // }),
      new HeaderLink({
        id: 'monthlyTimetable',
        name: 'TIMETABLE',
        path: '/monthly-timetable'
      }),
      new HeaderLink({
        id: 'downloads',
        name: 'DOWNLOADS',
        path: '/downloads'
      }),
      new HeaderLink({
        id: 'financial',
        name: 'FINANCIAL ACCOUNTS',
        path: '/financial-accounts'
      }),
      new HeaderLink({
        id: 'contactUs',
        name: 'CONTACT',
        path: '/contact-us'
      })
      // new HeaderLink({
      //   id: 'striveForFive',
      //   name: 'STRIVE',
      //   path: '/striveforfive'
      // })
    ]
  }

  getScreenState = () => {
    let newScreenState = '';
    if (window.innerWidth <= ScreenTypesResolutionDown.MD) {
      newScreenState = ScreenTypes.Mobile;
    } else {
      newScreenState = ScreenTypes.Desktop;
    }

    this.setState({
      currentScreenState: newScreenState
    });
  };

  render() {
    const { currentScreenState } = this.state;
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <div className="App">
            <Router history={history}>
              <Route path="/timetable-screen" render={() => <TimetableScreenContainer />} />
              {window.location.pathname !== "/timetable-screen" &&
                <>
                  <Header brandLogo={Logo} fixedTop navigation={App.makeHeaderLinks()}
                    currentScreenState={currentScreenState} />

                  <div id='app-section' className='container-fluid'>
                    <Route exact path="/"
                      render={() => <HomeContainer currentScreenState={currentScreenState} />} />
                    <Route path="/events" render={() => <EventsContainer />} />
                    <Route path="/monthly-timetable" render={() => <TimetableContainer />} />
                    <Route path="/contact-us" render={() => <ContactContainer />} />
                    <Route path="/event-info/:eventId" component={EventsInfoContainer} />
                    <Route path="/downloads" render={() => <DownloadsContainer />} />
                    <Route path="/financial-accounts" render={() => <FinancialContainer />} />
                    <Route path="/about-us" render={() => <AboutContainer />} />
                    <Route path="/striveforfive" render={() => <Strivefor5Container />} />
                    <RedirectsRoutes />
                    <PagesRoutes />
                  </div>
                  <Footer />
                </>
              }
            </Router>
          </div>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }

}

export default App;
