

import * as React from "react";
import {fetchTimetable} from "../../api/timetable";
import moment, { Moment } from 'moment'
import {SalahTime} from "../../components/monthly-timetable/SalahTime";
import {MonthlyTimetable} from "../../components/monthly-timetable/MonthlyTimetable";


export interface TimetableContainerProps {
}

export interface TimetableContainer {
    currentDate: Moment;
    salahTimes: SalahTime[];
}

export class TimetableContainer extends React.Component<any, any> {

    constructor(props: TimetableContainerProps) {
        super(props);
        this.state = {
            currentDate: moment(),
            salahTimes: null
        }
    }

    getSalahTimes() {
        const { currentDate } = this.state;
        fetchTimetable(currentDate.year(), currentDate.month() + 1, currentDate.date(), true).then(salahTimes => {
            this.setState({ salahTimes: salahTimes });
        })
    }

    componentDidMount() {
        this.getSalahTimes();
    }

    render() {
        const { salahTimes } = this.state;

        return (
            <div>
                {
                salahTimes && <MonthlyTimetable salahTimes={salahTimes}/>
                }
            </div>
        );
    }
}