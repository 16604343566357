import React, { Component } from 'react';
import { IDefaultProps } from '../../common/props/DefaultProps';
import { default as classnames } from 'classnames';


interface IFinancialContainerProps extends IDefaultProps {
}

interface IFinancialContainerState {
}

export class FinancialContainer extends Component<IFinancialContainerProps, IFinancialContainerState> {
 
    render() {
        const { id, classes } = this.props;
        return (

            <div id={id} className={classnames('FinancialContainer container',classes)}>
                <h1 className="text-center">Financial Accounts</h1>
                <p className="text-center"><strong>Jalalia Jaame Mosque Annual reports and accounts:</strong>
                <br/>
                <br/>The Charities Act 2011 requires all registered charities to prepare a Trustees' Annual Report and accounts.
                <br/>
                <br/>Registered charities with a financial period ending on or after 1 April 2009 must send their Trustees' Annual Report and accounts to the Charities Commission if their income was greater than £25,000.
                <br/>
                <br/>The charities commission make copies of the accounts and Trustees' Annual Reports publicly available.
                <br/>
                <br/>Please click the appropriate links below to see Jalalia Jaame Mosque submitted accounts.
                <br/>
                <br/>The links below will open a pdf document for you to view the Trustees' Annual Report and accounts in printable form.</p>
               
                <p className="text-center"><a href="https://apps.charitycommission.gov.uk/Showcharity/RegisterOfCharities/CharityWithoutPartB.aspx?RegisteredCharityNumber=1152215&amp;SubsidiaryNumber=0">Visit our charity commission profile:&nbsp;</a></p>
                <h3 className="text-center"><a href="https://apps.charitycommission.gov.uk/Accounts/Ends15/0001152215_AC_20141231_E_C.pdf">2014 Accounts</a></h3>
                <h3 className="text-center"><a href="https://apps.charitycommission.gov.uk/Accounts/Ends15/0001152215_AC_20151231_E_C.pdf">2015 Accounts</a></h3>
                <h3 className="text-center"><a href="https://apps.charitycommission.gov.uk/Accounts/Ends15/0001152215_AC_20161231_E_C.pdf">2016 Accounts</a></h3>
                <h3 className="text-center"><a href="https://apps.charitycommission.gov.uk/Accounts/Ends15/0001152215_AC_20171231_E_C.pdf">2017 Accounts</a></h3>
                <h3 className="text-center"><a href="https://apps.charitycommission.gov.uk/Accounts/Ends15/0001152215_AC_20181231_E_C.pdf">2018 Accounts</a></h3>
            </div>
        );
    }
}

export default FinancialContainer;