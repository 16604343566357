export class HeaderLink {
    id?: string;
    name?: string | JSX.Element;
    path?: string;
    active?: boolean = false;
    disabled?: boolean = false;

    constructor(
        fields?: {
            id?: string,
            name?: string | JSX.Element,
            path?: string,
            active?: boolean,
            disabled?: boolean
        }

    ) {
        if (fields) Object.assign(this, fields);
    }
}