import React from 'react';
import Events from '../../components/event/Events'
import {useEventsListQuery} from '../../generated/graphql';

const EventsContainer = () => {
    
    const { data, error, loading } = useEventsListQuery();
    
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !data) {
        return <div>ERROR</div>;
    }

    return <div id='EventsContainer' className={'container'}><Events data={data}/></div>;
};

export default EventsContainer;


