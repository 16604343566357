import { useRedirectsListQuery, RedirectsListQuery } from '../../generated/graphql';
import React from 'react';
import { Route } from 'react-router';

interface RedirectsProps {
    redirects: RedirectsListQuery
}

const RedirectsRoutes = () => {
    const { data, error, loading } = useRedirectsListQuery();

    if (loading) {
        return <div></div>;
    }

    if (error || !data) {
        return <div></div>;
    }

    return (
        <Redirects redirects={data}></Redirects>
    )
};

const Redirects: React.FC<RedirectsProps> = ({ redirects }) => {
    return (
        <React.Fragment>
            {!!redirects.redirects &&
                redirects.redirects.map((redirect, key) => !!redirect && (
                    <Route key={key} path={`${redirect.source}`} component={() => { window.location.href = `${redirect.destination}`; return null; }} />
                ))}
        </React.Fragment>);
};

export default RedirectsRoutes

