import React, { Component } from 'react';
import { IDefaultProps } from '../../common/props/DefaultProps';
import { default as classnames } from 'classnames';


interface IContactContainerProps extends IDefaultProps {
}

interface IContactContainerState {
}

export class ContactContainer extends Component<IContactContainerProps, IContactContainerState> {
 
    render() {
        const { id, classes } = this.props;
        return (

            <div id={id} className={classnames('ContactContainer',classes)}>
               <h1 className="text-center">Contact Us</h1><br/>               
               <p>66 Trafalgar Street, Wardleworth,
	              <br/>Rochdale, Lancashire,
	              <br/>OL16 2EB</p>
                  <p>Telephone: 01706 646822</p>
            </div>
        );
    }
}

export default ContactContainer;