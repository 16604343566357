import React from 'react';
import 'moment-timezone';
import {useEventInfoQuery} from "../../generated/graphql";
import {RouteComponentProps} from "react-router";
import EventInfo from "../../components/event-info/EventsInfo";

type EventInfoParams = { eventId: string };

const EventsInfoContainer = ({match}:RouteComponentProps<EventInfoParams>) => {
    const {data, error, loading} = useEventInfoQuery({variables: {id: match.params.eventId}});
    
    if (loading) {
        return <div className={'container'}>Loading...</div>;
    }

    if (error || !data) {
        return <div className={'container'}>ERROR</div>;
    }

    return <div id='EventInfoContainer' className={'container'}><EventInfo data={data}/></div>;
};

export default EventsInfoContainer;


