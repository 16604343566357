import React from 'react';
import { IDefaultProps } from '../../common/props/DefaultProps';
import { default as classnames } from 'classnames';

export interface TileTitleProps extends IDefaultProps {
    title: string;
}

export const TileTitle: React.SFC<TileTitleProps> = (props) => {
    const { id, classes } = props;
    return (
        <div id={id} className={classnames('titleContainer', classes)}>
            <h2>{props.title}</h2>
        </div>
    );
};