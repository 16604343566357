import React, { Component } from 'react';
import { IDefaultProps } from '../../common/props/DefaultProps';
import { TileTitle } from '../../components/tile/TileTitle';
import Banner from '../../assets/img/web-banner.jpg';
import Mosque from '../../assets/img/main-image.jpeg';
import { default as classnames } from 'classnames';

export interface IHomeContainerProps extends IDefaultProps {
}

export interface IHomeContainerState {
}

export default class HomeContainer extends Component<IHomeContainerProps, IHomeContainerState> {

    // Todo create iframe component and pass width through
    // Width has to be equal to widget container width after it renders
    componentDidMount(){
        var iframeDiv = document.getElementById('facebook-iframe-div');
        if(iframeDiv != null) {
            var iframeWidth = iframeDiv.offsetWidth-15;
            var iframeHeight = iframeDiv.offsetHeight;

            if(iframeWidth > 500){
                iframeWidth=500;
            }

            if(iframeHeight < 200){
                iframeHeight=200;
            }

            var makeIframe = document.createElement("iframe");
            makeIframe.setAttribute("src", "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FJalaliaJaameMosque%2F&tabs=timeline&width=" + iframeWidth + "&height="  + iframeHeight + "&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId");
            makeIframe.setAttribute("scrolling", "no");
            makeIframe.setAttribute('width', iframeWidth.toString());
            makeIframe.setAttribute('height', iframeHeight.toString());
            makeIframe.setAttribute('frameborder', '0');
            makeIframe.setAttribute('allow', 'encrypted-media');
            makeIframe.setAttribute('allowTransparency', 'true');

            makeIframe.style.border = "none";
            makeIframe.style.overflow = "hidden";

            iframeDiv.appendChild(makeIframe)

        }

    }

    public render() {
        const { id, classes, currentScreenState } = this.props;
        return (
            <div id={id} className={classnames('HomeContainer container', classes, currentScreenState)}>
                <div className='main-image-container'>
                        <a href='#'>
							<img className='main-image' src='/TopImage01.jpg' style={{width:'100%'}}/>
						</a>
                </div>


                <div className='content'>
                    <div className='row px-4'>
                        <div className='col-12 col-md-6 p-4'>
                            <TileTitle title='Visit Us' />
                            <div className='panel-content'>
                                <h4>
                                    Jalalia Jaame Mosque
                                <br />
                                    66 Trafalgar Street
                                <br />
                                    Rochdale, OL16 2EB
                                <br />
                                    <br />
                                    01706 646822<br/>
                                    info@jalaliajaamemosque.org.uk
                                </h4>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 p-4 hide-panel'>
                            <TileTitle title='Upcoming Prayer' />
                            <div className='panel-content'>
                                <h4>
                                    Fajr Jama'ah: <b>4:00am</b>
                                    <br />
                                    <br />
                                    Time Remaining:
                                <br />
                                    <b>2 Hours & 15 Minutes</b>
                                </h4>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 p-4 hide-panel'>
                            <TileTitle title='Latest' />
                            <div className='panel-content'>
                                <h4>
                                    Making the most out of Dhul Hijjah
                                </h4>
                                <br />
                                <br />
                                <button className='btn btn-outline-primary float-right'>
                                    Read More
                                </button>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 p-4'>
                            <TileTitle title='Follow Us' />
                            <div id={'facebook-iframe-div'} className='panel-content facebook-iframe'>
                                {/* <iframe className={''} title={'facebook-widget'} style={{marginLeft:'auto', marginRight:'auto', display:'block'}} src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fen-gb.facebook.com%2FJalaliaJaameMosque&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="100px"  scrolling="no" frameBorder="0" allow="encrypted-media"></iframe> */}
                                {/* <iframe src="" width="180" height="500" scrolling="no" frameBorder="0" allow="encrypted-media"></iframe> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
