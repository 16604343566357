import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IDefaultProps } from "../../../common/props/DefaultProps";
import { HeaderLink } from "./types/HeaderLink";
import { ScreenTypes } from "../../../enums/ScreenTypes";
import { default as classnames } from "classnames";
import Timetable, { TimetableMode } from "../../timetable/Timetable";

interface IHeaderProps extends IDefaultProps {
  brandName?: string | JSX.Element;
  brandLogo?: string;
  brandLink?: string;
  container?: boolean;
  fixedTop?: boolean;
  navigation?: HeaderLink[];
}

interface IHeaderState {
  navigation: HeaderLink[];
  collapsed: boolean;
  splashState: string;
}

export class Header extends Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);

    this.state = {
      navigation: props.navigation ? props.navigation : [],
      collapsed: true,
      splashState: "shown",
    };
  }

  getCurrentActiveNav = (nav: HeaderLink) => {
    return (e: React.SyntheticEvent) => {
      e.preventDefault();
      const navigation = this.state.navigation;

      navigation.map((n) => {
        return nav.id === n.id ? (n.active = true) : (n.active = false);
      });

      this.setState({
        navigation,
        collapsed: true,
      });
    };
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  animateSplashScreen = (finalState: string) => {
    const fadeOutDelay = 300;
    const hideTimer = 500;
    setTimeout(() => {
      this.setState(
        {
          splashState: "faded",
        },
        () => {
          setTimeout(() => {
            this.setState({
              splashState: finalState,
            });
          }, hideTimer);
        }
      );
    }, fadeOutDelay);
  };

  render() {
    const {
      id,
      classes,
      currentScreenState,
      brandName,
      brandLogo,
      brandLink,
      fixedTop,
    } = this.props;
    const { collapsed } = this.state;

    const collapseShow = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    return (
      <div id={id} className={classnames("HeaderContainer", classes)}>
        <nav
          className={`navbar navbar-expand-lg ${
            fixedTop ? "fixed-top" : ""
          } navbar-fixed-top`}
        >
          <Link
            className={`navbar-brand ${currentScreenState}`}
            to={brandLink ? brandLink : ""}
          >
            {brandLogo ? (
              <div className="brand-logo-container">
                <img src={brandLogo} alt="Brand Logo" />
              </div>
            ) : (
              <div />
            )}
            {brandName}
          </Link>

          <div className="button-links">
            <a
              href="https://jalaliajaamemosque.enthuse.com/membershipfee"
              className="btn btn-primary btn-lg donate-button-mobile"
            >
              Membership Fee
            </a>
            <a
              href="https://jalaliajaamemosque.enthuse.com/donation"
              className="btn btn-primary btn-lg donate-button-mobile"
            >
              Donate
            </a>
            {/* <a
              href="https://givebrite.com/rochdales-ramadan-in-rohingya"
              className="btn btn-primary btn-lg donate-button-mobile"
            >
              Rohingya Appeal
            </a> */}
          </div>

          <Timetable displayMode={TimetableMode.Horizontal} />
          {currentScreenState === ScreenTypes.Mobile ? (
            <div
              id="menu-toggle"
              className={`${collapsed ? "" : "open"}`}
              onClick={this.toggleNavbar}
            >
              <p>Menu</p>
              <span></span>
              <span></span>
              <span></span>
              <span id="remove-me"></span>
            </div>
          ) : (
            <div />
          )}

          <div
            className={`text-center ${collapseShow} ${currentScreenState}`}
            id="main-nav"
          >
            <ul className="navbar-nav">
              {this.state.navigation.map((nav) => {
                return (
                  <li
                    className="nav-item"
                    onClick={this.getCurrentActiveNav(nav)}
                    key={nav.id}
                  >
                    <Link
                      className={`nav-link ${nav.active ? "active" : ""}`}
                      to={nav.path ? nav.path : ""}
                    >
                      {nav.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
