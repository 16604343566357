import React from "react";
import Timetable, { TimetableMode } from "../../components/timetable/Timetable";
import Logo from '../../assets/img/logo.png';
import { Clock } from "../../components/clock/Clock";
import { HijriDate } from "../../components/hijri-date/HijriDate";
import { useScreensListQuery } from "../../generated/graphql";
import { Slideshow } from "./Slideshow";

const ScreensList: React.FC = () => {

  const { data, error, loading } = useScreensListQuery();

  if (loading) {
    return <div></div>;
  }

  if (error || !data) {
    return <div>ERROR</div>;
  }

  let listOfImages: Array<string>;
  listOfImages = [];



  !!data && data.screens && data.screens.forEach(function (screen: any) {
    !!screen && !!screen.images && screen.images.forEach(function (image: any) {
      listOfImages.push(image && image.url);
    });
  });

  return <React.Fragment>
    <div className={'slides-container'}>
      <Slideshow currentImage={0} images={listOfImages} />
    </div>
  </React.Fragment>;
};

export interface TimeTableScreenContainerState {
  currentImage: number;
  images: string[];
}

export class TimetableScreenContainer extends React.Component {

  componentDidMount() {
    setInterval(
        () => window.location.reload(),
        1800000//refresh every 30 minutes
    );
  }

  render() {
    return (

      <div id="TimetableScreenContainer" className={"timetable-screen container-fluid"}>

        <div className={"row"}>
          <div className={"col-sm-4 left-timetable"}>
            <div className={"text-center logo text"}>
              <img src={Logo} alt="jalalia logo" height="140" />
            </div>

            <Clock />

            <Timetable displayMode={TimetableMode.Vertical} />

          </div>

          <div className='col-sm-8 right-slideshow'>
            <HijriDate className="col-sm-12 hijra-date" />

            <ScreensList />
            <div className="col-sm-12 contact-info">
              {/* <img className="contact-all" src="https://www.jalaliajaamemosque.org.uk/themes/jalalia-original/assets/images/contact-info.png" alt="phone number" /> */}
            </div>
          </div>

        </div>

      </div>

    );
  }

}

export default TimetableScreenContainer;