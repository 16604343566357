import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import React from "react";

export interface SlideshowState {
    currentImage: number;
    images: string[];
}


export class Slideshow extends React.Component<any, SlideshowState> {

    private slideShow: any;

    constructor(props: SlideshowState) {
        super(props);
        this.state = {
            currentImage: props.currentImage,
            images: props.images

        };
    }

    componentDidMount() {
        this.slideShow = setInterval(
            () => this.nextSlide(),
            30000
        );
    }

    nextSlide() {
        this.setState({
            currentImage: (this.state.currentImage + 1) % this.state.images.length
        });

    }

    componentWillUnmount() {
        clearInterval(this.slideShow);
    }

    render() {
        return (
            <ReactCSSTransitionGroup
            transitionName="fade"
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={300} >
                <img key={this.state.currentImage} alt={'screen'} className='slideshow-images' src={`${process.env.REACT_APP_CMS_API}` + this.state.images[this.state.currentImage]} />
            </ReactCSSTransitionGroup>
        );
    }

}