import React from 'react';
import { useDownloadsListQuery, DownloadsListQuery } from "../../generated/graphql";


interface Props {
    data: DownloadsListQuery
}

interface File {
    id: string,
    name: string,
    url: string
}

interface Results {
    active: boolean,
    description: string,
    files: File[],
    title: string
}

const DownloadsContainer: React.SFC = () => {

    const { data, error, loading } = useDownloadsListQuery();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !data) {
        return <div>ERROR</div>;
    }



    return <div id={'DownloadsContainer'} className={'container'}>
        <Downloads data={data} />
    </div>;
};

const Downloads: React.FC<Props> = ({ data }) => {
    return (<React.Fragment>
        {!!data.sortedDownloads && !!data.sortedDownloads.results &&
            data.sortedDownloads.results.map((download: Results, index: any) => !!download && (<div key={index} className={''}>
                <div>
                    <table className={"table border downloads-table"}>
                        <thead>
                            <tr className={"downloads-table-headings"}>
                                <th colSpan={2} className={"text-center"}>{download.title}</th>
                            </tr>
                            <tr className={'file-names-heading'}>
                                <th>Filename</th>
                                <th className={"text-center"}>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!download.files && download.files.map((file: File, key: any) =>
                                <tr key={key} className={"row-highlight"}>
                                    <th className={"align-middle file-names"} scope="row">
                                        <a  download={`${!!file && file.name}`} href={`${process.env.REACT_APP_DOWNLOADS}${!!file && file.url}`}>{!!file && file.name}
                                        </a>
                                    </th>
                                    <td className={"text-center file-names"}>
                                        <a download={`${!!file && file.name}`} className={'btn btn-primary'} href={`${process.env.REACT_APP_DOWNLOADS}${!!file && file.url}`}>Download</a>
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
            </div>))}
    </React.Fragment>);
};

export default DownloadsContainer;