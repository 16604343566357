export enum ScreenTypes {
    Desktop = 'desktop',
    Mobile = 'mobile',
    Tablet = 'tablet'
}

export enum ScreenTypesResolutionDown{
    XS = 575.98,
    SM = 767.98,
    MD = 991.98,
    LG = 1199.98,
}

export enum ScreenTypesResolutionUp{
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
}