import React from 'react';
import ReactMarkdown from 'react-markdown';

export interface Page {
    content?: string | null,
    title?: string | null
}

const PageContent: React.FC<Page> = ({ content, title }) => {
    return (
        <React.Fragment>
            <div className={'PageContainer container'}>
                <h1 className={'title-center'}> {title} </h1>
                <ReactMarkdown>
                    {content}
                </ReactMarkdown>
            </div>
        </React.Fragment>);
};


export default PageContent;