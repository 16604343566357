import React, { Component } from 'react';
import { IDefaultProps } from '../../common/props/DefaultProps';
import { default as classnames } from 'classnames';


interface IAboutContainerProps extends IDefaultProps {
}

interface IAboutContainerState {
}

export class AboutContainer extends Component<IAboutContainerProps, IAboutContainerState> {
 
    render() {
        const { id, classes } = this.props;
        return (

            <div id={id} className={classnames('ContactContainer container',classes)}>
               <h1 className="text-center">About Us</h1><br/>
               <p>Jalalia Jaame Mosque was established in 1971 with the purchase of a terraced house on Dudley Street, Rochdale. However soon after this, Rochdale council acquired the property under a compulsory purchase order and demolished it.</p>           
               <p>Undeterred by the setback, the community came together and acquired land on Dudley Street, successfully erecting Rochdale's first purpose-built mosque.</p>
               <figure>
                   <img alt="People" src="/people.jpeg" style={{width:'100%'}}/>
                   <figcaption style={{fontWeight:"bold"}}>Image: Members of the community gather to mark the acquisition of land in Dudley Street</figcaption>
               </figure>
             
               <p>The mosque was completed in the early 80's and was able to accommodate up to 100 worshippers. As the community grew so did the services of the mosque, which started offering Islamic education classes for young people in the evenings.</p>
               <p>Over the years the mosque was gradually extended and services expanded to cater to the needs of the ever growing worshippers. By 2004, Jalalia Jaame Mosque was able to accommodate up to 1500 worshippers. A large green dome and minaret were installed making the mosque a focal point for the community in Wardleworth, central Rochdale.</p>
               <br/>

               <figure>
                   <img alt="Inside mosque" src="inside-mosque.jpeg" style={{width:'100%'}}/>
                   <figcaption style={{fontWeight:"bold"}}>Image: Main prayer hall 2018</figcaption>
               </figure>

               
               <p>Jalalia Jaame Mosque is currently divided into three areas consisting of two large prayer rooms used for daily prayers, educational activities for youngsters and other similar activities during the week. A third area consists of a Lobby, Ablution area and toilet/shower facilities, Kitchen, Library, Imam's office and a Management office which is used for administration purposes.</p>
               <p>In 2019, the mosque was extended due to the need for a much needed purpose built mortuary facility which included new wash and refrigeration facilities.</p>
               <p>Jalalia Jaame Mosque has been established for over 48 years and continues to be used by the ever growing community as a place for solace and spiritual development.</p>

            </div>
        );
    }
}

export default AboutContainer;