import React from "react";
import {SalahTime} from "../monthly-timetable/SalahTime";
import moment, { Moment } from 'moment'

export interface VerticalTimetableProps {
    salahTime: SalahTime;
}

export interface VerticalTimetableState {
    salahTime: SalahTime;
}

export class VerticalTimetable extends React.Component<{ currentDate: moment.Moment, hijriDateString: string, salahTimes: SalahTime }> {
    render() {

        return <div id={'TimetableScreen'}>
            <div className={"text-center date-text text-labels"}>{this.props.currentDate.format("ddd Do MMM YYYY")}</div>
            {this.props.salahTimes && <div>
                <table className={'table-container'} cellPadding={'8'}>
                    <thead>
                    <tr>
                        <th></th>
                        <th className={"text-labels"}>Start</th>
                        <th className={"text-labels"}>Jamat</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={"text-labels-left"}>Fajr</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.fajrStart, "HH:mm:ss").format("h:mm")}</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.fajrJamat, "HH:mm:ss").format("h:mm")}</td>
                    </tr>

                    <tr>
                        <td className={"text-labels-left"}>Sunrise</td>
                        <td className={"text-labels"}></td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.sunrise, "HH:mm:ss").format("h:mm")}</td>
                    </tr>

                    <tr>
                        <td className={"text-labels-left"}>Zuhr</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.zuhrStart, "HH:mm:ss").format("h:mm")}</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.zuhrJamat, "HH:mm:ss").format("h:mm")}</td>
                    </tr>

                    <tr>
                        <td className={"text-labels-left"}>Asr</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.asrStart, "HH:mm:ss").format("h:mm")}</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.asrJamat, "HH:mm:ss").format("h:mm")}</td>
                    </tr>

                    <tr>
                        <td className={"text-labels-left"}>Maghrib</td>
                        <td className={"text-labels"}></td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.magrib, "HH:mm:ss").format("h:mm")}</td>
                    </tr>

                    <tr>
                        <td className={"text-labels-left"}>Isha</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.ishaStart, "HH:mm:ss").format("h:mm")}</td>
                        <td className={"text-labels"}>{moment.utc(this.props.salahTimes.ishaJamat, "HH:mm:ss").format("h:mm")}</td>
                    </tr>
                    </tbody>
                </table>
            </div>}
            <div className='clearfix'></div>
        </div>;
    }
}