import React, { Component } from 'react';
import { IDefaultProps } from '../../common/props/DefaultProps';
import { default as classnames } from 'classnames';


interface IStrivefor5ContainerProps extends IDefaultProps {
}

interface IStrivefor5ContainerState {
}

export class Strivefor5Container extends Component<IStrivefor5ContainerProps, IStrivefor5ContainerState> {
 
    render() {
        const { id, classes } = this.props;
        return (
            <div id={id} className={classnames('Strivefor5Container container',classes)}>
				<div className='row'>
					<div className='col-12'>
						<img alt='Salah Title Banner' src='/web-banner.jpg' style={{marginBottom:'50px', width:'100%'}}/>
					
					</div>
				</div>

				<div>
					<h1 style={{marginBottom:'20px', fontSize:'4.5rem'}} className='text-center'>Strive for Five: Winter Scheme</h1>
				</div>
				<p style={{marginBottom:'40px', fontSize:'2.0rem'}} className={"text-center"}>
					We’ve got amazing line-up of activities for you this winter.
				</p>
				<div>
					<h1 style={{marginBottom:'61px', fontSize:'4.5rem'}} className='text-center'>Competitions</h1>
				</div>
				<div className='row'>
					<div className='col-12 col-md-6' style={{marginBottom:'50px'}}>
						<a href='https://forms.gle/vDkCwuDjbTgg3yLh6'>
							<img alt='Poetry Competition' src='/striveforfive/poetry-web-box.jpg' style={{width:'100%'}}/>
							<p className={"blue-text"} >Submit</p>
						</a>
					</div>
					<div className='col-12 col-md-6' style={{marginBottom:'50px'}}>
						<a href='https://forms.gle/nojCWoKNTUn4mP4u6'>
							<img alt='Jamaat Challenge' src='/striveforfive/jamaat-web-box.jpg' style={{width:'100%'}}/>
							<p className={"blue-text"} >Register</p>
						</a>
					</div>
				</div>
				<div>
					<h1 style={{marginBottom:'61px', fontSize:'4.5rem'}} className='text-center'>Activities</h1>
				</div>
				<div className='row'>
					<div className='col-12 col-md-6' style={{marginBottom:'50px'}}>
						<a href='https://forms.gle/2VYBis8FUWw9LuH77'>
							<img alt='Quran Circle' src='/striveforfive/quran-webbox.jpg' style={{width:'100%'}}/>
							<p className={"blue-text"} >Register</p>
						</a>
					</div>
					<div className='col-12 col-md-6' style={{marginBottom:'50px'}}>
						<a href='https://forms.gle/jED8ib2K63jghaP38'>
							<img alt='Family Quiz' src='/striveforfive/family-quiz-web-box.jpg' style={{width:'100%'}}/>
							<p className={"blue-text"}>Register</p>
						</a>
					</div>
				</div>
				<div>
					<h1 style={{marginBottom:'61px', fontSize:'4.5rem'}} className='text-center'>Inspirational Reminders</h1>
				</div>
				
				<div className='row'>
					
					<div className='col-12 col-md-4' style={{marginBottom:'50px'}}>
					
						<img alt='Talk 1' src='/striveforfive/lecture-1-parents.jpg' style={{marginBottom:'20px', width:'100%'}}/>
					
					</div>
					<div className='col-12 col-md-4' style={{marginBottom:'50px'}}>
						<img alt='Talk 2' src='/striveforfive/Lecture2-Key-to-Jannah.jpg' style={{marginBottom:'20px', width:'100%'}}/>
		
					</div>
					<div className='col-12 col-md-4' style={{marginBottom:'50px'}}>
						<img alt='Talk 3' src='/striveforfive/Lecture-3-New-year-new-me.jpg' style={{marginBottom:'20px', width:'100%'}}/>
	
					</div>
				</div>
            </div>
        );
    }
}

export default Strivefor5Container;