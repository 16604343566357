import React from 'react';
import { IDefaultProps } from '../../../common/props/DefaultProps';
import { default as classnames } from 'classnames';

export interface FooterProps extends IDefaultProps {
}

export const Footer: React.SFC<FooterProps> = (props) => {
    const { id, classes } = props;
    const currentYear = new Date().getFullYear();
    return (
        <div id={id} className={classnames('Footer-component', classes)}>
            <nav className="navbar navbar-light text-light text-center">
                <span className='copyright-notice'>&copy; {currentYear} Jalalia Jaame Mosque. All rights reserved, unless used for dawah purposes.</span>
            </nav>
        </div>
    );
};