import React from 'react';
import {EventsListQuery} from "../../generated/graphql";
import moment from 'moment';
import history from '../../history';


interface Props {
    data: EventsListQuery
}

function getDate(value:any, format:string) {
    if(value !== undefined && value !== ""){
        return moment(value.replace('Z','')).format(format);
    }
    return ""
}

const Events: React.SFC<Props> = ({ data }) => {
    const handleClickEvent = (event:any) => {
          history.push("/event-info/" + event.event.id);
      };

    return (<React.Fragment>
        {!!data.events &&
            data.events.map((event, index) => !!event && (<div key={index} className={'row event-row'} onClick={() => handleClickEvent({event})}>
                <div className="col-md-2 calendar-section">
                    <p className="calendar">
                        {getDate(event.startDate, "D MMMM")}<em />
                        <span className={'small-year'}>{getDate(event.startDate, "YYYY")}</span>
                    </p>
                </div>

                <div className={'col-md-7 events-details-section'}>

                    <h3>{event.title}</h3>
                    <p><i className="fas fa-user" /> {event.contact}</p>
                    <p>
                        <i className="far fa-calendar-alt" /> {getDate(event.startDate, "dddd Do MMMM YYYY")}&nbsp;&nbsp;
                        <i className="far fa-clock" /> {getDate(event.startDate, "ha")} - {getDate(event.endDate, "ha")}
                    </p>
                    <p><i className="fas fa-map-marker-alt" /> {event.location}</p>
                </div>
                <div className={'col-md-2'}>
                    <img className={'event-image-small'} src={`${process.env.REACT_APP_CMS_API}${event.image && event.image.url}`} alt="Placeholder" />
                </div>
            </div>))}
    </React.Fragment>);
};


export default Events;
