import React from "react";
import moment, { Moment } from 'moment'

export interface ClockState {
    time: Moment;
}

export class Clock extends React.Component<any, ClockState>{
    private timer: any;

    constructor(props: any) {
        super(props);
        this.state = {
            time: moment()
        };
    }

    componentDidMount() {
        this.timer = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick() {
        this.setState({
            time: moment()
        });
    }

    render()  {
        return (
            <div className={"clockContainer"}>
                {this.state.time.format("h:mm a")}
            </div>
        );
    }

}