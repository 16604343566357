

import * as React from "react";
import moment from 'moment'
import {SalahTime} from "./SalahTime";
import {formatSalahTime} from "../../common/utils/dateFormatter";

export interface TimetableRowProps {
    salahTime: SalahTime;
}

export interface TimetableRow {
    salahTime: SalahTime;
}

export class TimetableRow extends React.Component<any, any> {

    constructor(props: TimetableRowProps) {
        super(props);
        this.state = {
            salahTime: props.salahTime
        }
    }

    render() {
        const { salahTime } = this.state;
        const momentDate = moment(salahTime.fullDate, "YYYY:MM:DD");

        return (
            <tr key={salahTime.fullDate} className={momentDate.format("ddd") === 'Fri' ? 'monthlyTimetable__row--highlight monthlyTimetable__cells' : 'monthlyTimetable__cells'}>
                <td className="monthlyTimetable__cell">{momentDate.format("Do MMM")}</td>
                <td className="monthlyTimetable__cell">{momentDate.format("ddd")}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.fajrStart)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.fajrJamat)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.sunrise)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.zuhrStart)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.zuhrJamat)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.asrStart)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.asrJamat)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.magrib)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.ishaStart)}</td>
                <td className="monthlyTimetable__cell">{formatSalahTime(salahTime.ishaJamat)}</td>
            </tr>
        );
    }
}