import React from "react";
import {SalahTime} from "./SalahTime";
import {TimetableRow} from "./TimetableRow";
import moment from 'moment'

export interface MonthlyTimetableProps {
    salahTimes: SalahTime[];
}

export interface MonthlyTimetableState {
    salahTimes: SalahTime[];
}

export class MonthlyTimetable extends React.Component<any, any> {

    constructor(props: MonthlyTimetableProps) {
        super(props);
        this.state = {
            salahTimes: props.salahTimes
        }
    }

    render() {
        const { salahTimes } = this.state;

        return (
            <>
                <table className='monthlyTimetable'>
                    <thead>
                    <tr className="monthlyTimetable__headerTtitleContainer">
                            <th className="monthlyTimetable__prayerTitleCell" colSpan={12}>
                                <span className="monthlyTimetable__title">
                                    Monthly Prayer Times {
                                        salahTimes[0].fullDate && (
                                            <span>for {moment(salahTimes[0].fullDate).format('MMMM')}</span>
                                        )
                                    }
                                </span>
                            </th>
                        </tr>
                        <tr className="monthlyTimetable__headerGroupPrayers">
                            <th className="monthlyTimetable__prayerHeadCell--date"></th>
                            <th className="monthlyTimetable__prayerHeadCell--day"></th>
                            <th className="monthlyTimetable__prayerHeadCell" colSpan={3}>
                                <span className="monthlyTimetable__prayerName">
                                    Fajr
                                </span>
                            </th>
                            <th className="monthlyTimetable__prayerHeadCell" colSpan={2}>
                                <span className="monthlyTimetable__prayerName">
                                    Zuhr
                                </span>
                            </th>
                            <th className="monthlyTimetable__prayerHeadCell" colSpan={2}>
                                <span className="monthlyTimetable__prayerName">
                                    Asr
                                </span>
                            </th>
                            <th className="monthlyTimetable__prayerHeadCell monthlyTimetable__prayerHeadCell--magarib">
                                <span className="monthlyTimetable__prayerName">
                                    Maghrib
                                </span>
                            </th>
                            <th className="monthlyTimetable__prayerHeadCell" colSpan={2}>
                                <span className="monthlyTimetable__prayerName">
                                    Isha
                                </span>
                            </th>
                        </tr>
                        <tr className="monthlyTimetable__headerTitles">
                            <th className="monthlyTimetable__headerCell">Date</th>
                            <th className="monthlyTimetable__headerCell">Day</th>
                            <th className="monthlyTimetable__headerCell">Begins</th>
                            <th className="monthlyTimetable__headerCell">Iqamah</th>
                            <th className="monthlyTimetable__headerCell">Sunrise</th>
                            <th className="monthlyTimetable__headerCell">Begins</th>
                            <th className="monthlyTimetable__headerCell">Iqamah</th>
                            <th className="monthlyTimetable__headerCell">Begins</th>
                            <th className="monthlyTimetable__headerCell">Iqamah</th>
                            <th className="monthlyTimetable__headerCell">Begins</th>
                            <th className="monthlyTimetable__headerCell">Begins</th>
                            <th className="monthlyTimetable__headerCell">Iqamah</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            salahTimes && (salahTimes as SalahTime[]).map((salahTime, index) =>
                            <TimetableRow key={index} salahTime={salahTime}/>
                            )
                        }
                    </tbody>
                </table>
            </>

        );
    }
}