import { Moment } from "moment";

const {
  REACT_APP_API_BASE_URL: BASE_URL,
  REACT_APP_HIJRI_CONVERTER_URL: HIJRI_CONVERTER_URL,
  REACT_APP_CMS_API: CMS_API
} = process.env;

export const fetchTimetable = (
  year: number,
  month: number,
  day: number,
  fetchMonth: boolean = false
) => {
  let url = fetchMonth
    ? `${BASE_URL}timetable/get/${year}/${month}`
    : `${BASE_URL}timetable/get/${year}/${month}/${day}`;
  url += `?${Date.now()}`;
  return fetch(url).then((res) => {
    return res.json();
  });
};

const getHijriAdjustment = async ()=>{
  const url = `${CMS_API}/settings`
  return fetch(url).then(
    (res) => {
      return res.json();
    }
  );
}

export const fetchHijriDate = async (date: Moment) => {
  const hijriAdjustment = await getHijriAdjustment();
  const url = HIJRI_CONVERTER_URL?.replace('{HIJRI_ADJUSTMENT}', `${hijriAdjustment[0]?.myNumber || 0}`)
  const finalUrl = url?.replace('{DATE}', `${date.format("DD-MM-YYYY")}`)

  return fetch(`${finalUrl}`).then(
    (res) => {
      return res.json();
    }
  );
};
