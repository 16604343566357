import React from 'react';
import { fetchTimetable, fetchHijriDate } from '../../api/timetable';
import moment, { Moment } from 'moment'
import {VerticalTimetable} from "./VerticalTimetable";
import { HorizontalTimetable } from './HorizontalTimetable';

export enum TimetableMode {
    Vertical = 'vertical',
    Horizontal = 'horizontal',
}

export interface TimetableProps {
    displayMode: TimetableMode;
}

export interface TimetableState {
    currentDate: Moment;
    hijriDate: any;
    salahTimes: any;
    displayMode: TimetableMode;
}

export default class Timetable extends React.Component<TimetableProps, TimetableState> {
    constructor(props: TimetableProps) {
        super(props);
        this.state = {
            currentDate: moment(),
            hijriDate: null,
            salahTimes: null,
            displayMode: props.displayMode ? props.displayMode : TimetableMode.Horizontal
        }
    }
    componentDidMount() {
        this.getSalahTimes();
        this.getHijriDate()
    }

    getSalahTimes() {
        const { currentDate } = this.state;
        fetchTimetable(currentDate.year(), currentDate.month() + 1, currentDate.date()).then(salahTimes => {
            this.setState({ salahTimes: salahTimes[0] });
        })
    }

    getHijriDate() {
        const { currentDate } = this.state;
        fetchHijriDate(currentDate).then(hijriDate => {
            this.setState({ hijriDate: hijriDate.data.hijri });
        })
    }

    public render() {
        const { currentDate, salahTimes, hijriDate, displayMode } = this.state;
        const hijriDateString = hijriDate ? `${hijriDate.day} ${hijriDate.month.en} ${hijriDate.year}`
            : '';
        return (
            displayMode === TimetableMode.Horizontal
                ? <HorizontalTimetable currentDate={currentDate} hijriDateString={hijriDateString} salahTimes={salahTimes}/>
                : <VerticalTimetable currentDate={currentDate} hijriDateString={hijriDateString} salahTimes={salahTimes}/>
        );
    }
}
